import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import SmallImageHeader from '../../components/theme/SmallImageHeader'
import RenderBlock from '../../components/blocks/RenderBlock'

const AboutRoyale = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='zh-hans'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='zh-hans'
    />

    <SmallImageHeader
      subtitle={data.defaultPage.subtitle}
      description={data.defaultPage.description}
      title={data.defaultPage.title_html}
      image={data.defaultPage.image_url}
      alt={data.defaultPage.title}
      right
    />

    <RenderBlock blocks={data.defaultPage.blocks} />

    <Footer />

  </Layout>
)

export const query = graphql`
query {
  defaultPage(guid: { eq:"5c1c9570-0086-4a6f-ac11-7460dc6481ac"}, locale: {eq:"zh-hans"}) {
    title
    title_html
    subtitle
    description
    image_url
    blocks {
      __typename
      ...textBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...videoBlockFragment
      ...imageBlockFragment
      ...threeColumnWithIconsBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default AboutRoyale
